.mainBox[data-v-822e87d2] {
  width: 100%;
  height: 100%;
}
.tabsBox[data-v-822e87d2] {
  background-color: #FFFFFF;
  padding: 10px 12px;
  width: calc(100% - 24px);
  height: calc(100% - 65px);
}
[data-v-822e87d2] .el-tabs__content {
  background-color: transparent;
}
[data-v-822e87d2] .el-upload--picture-card,[data-v-822e87d2] .el-upload-list--picture-card .el-upload-list__item {
  width: 200px;
  height: 150px;
}